import React, { FC } from 'react';
import { ExtendableImage } from '@design-system/extendable-image';
import { ImageBlock as CSImageBlock } from '@design-system/image-block';

import { ImageBlockProps } from './models';

const ImageBlock: FC<ImageBlockProps> = ({ caption, images }) => (
  <CSImageBlock caption={caption}>
    {images.map((image, i) => (
      // TODO: find better key - asset_id? but what if images be duplicated?...
      // eslint-disable-next-line react/no-array-index-key
      <ExtendableImage key={i} {...image} />
    ))}
  </CSImageBlock>
);

export default ImageBlock;
